export default [
    {
        path: '/_students',
        name: 'students|home',
        component: () => import('@/views/students/home.vue')
    },
    {
        path: '/add-import',
        name: 'students|add',
        component: () => import('@/views/students/add-import.vue')
    },
    {
        path: '/students-messages',
        name: 'students-messages',
        component: () => import('@/views/students/students-messages.vue')
    },
    {
        path: '/_students/list',
        name: 'students|list',
        component: () => import('@/views/students/list.vue')
    },
    {
        path: '/students',
        name: 'students|list2',
        component: () => import('@/views/students/list.vue')
    },
    {
        path: '/_students/weeklyplan',
        name: 'students|weeklyplan',
        component: () => import('@/views/students/weeklyplan.vue')
    },
    {
        path: '/_students/settings/groups',
        name: 'students|settings|groups',
        component: () => import('@/views/students/settings/groups.vue')
    },
    {
        path: '/_students/settings/send',
        name: 'students|settings|send',
        component: () => import('@/views/students/settings/send.vue')
    },
    {
        path: '/_students/settings/ghiab-alerts',
        name: 'students|settings|ghiab-alerts',
        component: () => import('@/views/students/settings/ghiab-alerts.vue')
    },
    {
        path: '/_students/scan/barcode',
        name: 'students|scan|barcode',
        component: () => import('@/views/students/scan/barcode.vue')
    },
    {
        path: '/_students/scan/manual',
        name: 'students|scan|manual',
        component: () => import('@/views/students/scan/manual.vue')
    },
    {
        path: '/_students/scan/bulk',
        name: 'students|scan|bulk',
        component: () => import('@/views/students/scan/bulk.vue')
    },
    {
        path: '/_students/scan/notes',
        name: 'students|scan|notes',
        component: () => import('@/views/students/scan/notes.vue')
    },
    {
        path: '/_students/scan/ends',
        name: 'students|scan|ends',
        component: () => import('@/views/students/scan/ends.vue')
    },
    {
        path: '/_students/scan/end',
        name: 'students|scan|end',
        component: () => import('@/views/students/scan/end.vue')
    },
    {
        path: '/_students/reports/attend-before',
        name: 'students|reports|attend-before',
        component: () => import('@/views/students/reports/attend-before.vue')
    },
    {
        path: '/_students/reports/daily',
        name: 'students|reports|daily',
        component: () => import('@/views/students/reports/daily.vue')
    },
    {
        path: '/_students/reports/between',
        name: 'students|reports|between',
        component: () => import('@/views/students/reports/between.vue')
    },
    {
        path: '/archive',
        name: 'archive',
        component: () => import('@/views/students/archive.vue')
    },
    {
        path: '/_students/undirect-send/:date',
        name: 'students|undirect-send',
        component: () => import('@/views/students/reports/undirect-send.vue')
    },
    {
        path: '/_students/alerts',
        name: 'students|alerts',
        component: () => import('@/views/students/alerts.vue')
    },
    {
        path: '/_students/rates',
        name: 'students|rates',
        component: () => import('@/views/students/rates.vue')
    },
    {
        path: '/_students/health',
        name: 'students|health',
        component: () => import('@/views/students/health.vue')
    },
    {
        path: '/_students/reasons',
        name: 'students|reasons',
        component: () => import('@/views/students/reports/reasons.vue')
    },
    {
        path: '/_students/bis',
        name: 'students|bis',
        component: () => import('@/views/students/bis/index.vue')
    },
    {
        path: '/_students/reply-to-ghiab/:i',
        name: 'students|reply-to-ghiab',
        component: () => import('@/views/students/parents/reply-to-ghiab.vue'),
        meta: {
          layout: 'full',
        },
    },
    {
        path: '/_students/edit/:id',
        name: 'students|edit',
        component: () => import('@/views/students/edit.vue'),
    },
    {
        path: '/_students/hours',
        name: 'students|hours',
        component: () => import('@/views/students/hours.vue'),
    },
    {
        path: '/_students/certs',
        name: 'students|certs',
        component: () => import('@/views/students/certs.vue'),
    },
    {
        path: '/_students/breaks',
        name: 'students|breaks',
        component: () => import('@/views/students/breaks/breaks.vue'),
    },
    {
        path: '/_students/visits',
        name: 'students|visits',
        component: () => import('@/views/students/breaks/visits.vue'),
    },
    {
        path: '/_students/move',
        name: 'students|move',
        component: () => import('@/views/students/move.vue'),
    },
    {
        path: '/_students/freedays',
        name: 'students|freedays',
        component: () => import('@/views/students/freedays.vue'),
    },
    {
        path: '/_students/full-report/:id',
        name: 'students|full-report',
        component: () => import('@/views/students/reports/full-report.vue'),
    },
]