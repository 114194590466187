export default [
    {
        path: '/_teachers',
        name: 'teachers|home',
        component: () =>
            import('@/views/teachers/Home.vue')
    },
    {
        path: '/_teachers/scan',
        name: 'teachers|scan',
        component: () =>
            import('@/views/teachers/scan.vue')
    },
    {
        path: '/_teachers/scan/fingerprint',
        name: 'teachers|scan-fingerprint',
        component: () =>
            import('@/views/teachers/scan-fingerprint.vue')
    },
    {
        path: '/_teachers/scan/bulk',
        name: 'teachers|scan-bulk',
        component: () =>
            import('@/views/teachers/scan-bulk.vue')
    },
    {
        path: '/_teachers/scan/manual',
        name: 'teachers|scan-manual',
        component: () =>
            import('@/views/teachers/scan-manual.vue')
    },
    {
        path: '/_teachers/send',
        name: 'teachers|send',
        component: () =>
            import('@/views/teachers/send.vue')
    },
    {
        path: '/teachers-import',
        name: 'teachers-import',
        component: () =>
            import('@/views/teachers/import.vue')
    },
    {
        path: '/_teachers/add',
        name: 'teachers|add',
        component: () =>
            import('@/views/teachers/add.vue')
    },
    {
        path: '/_teachers/list',
        name: 'teachers|list',
        component: () =>
            import('@/views/teachers/list.vue')
    },
    {
        path: '/_teachers/edit/:i',
        name: 'teachers|edit',
        component: () =>
            import('@/views/teachers/edit.vue')
    },
    {
        path: '/_teachers/shifts',
        name: 'teachers|shifts',
        component: () =>
            import('@/views/teachers/shifts.vue')
    },
    {
        path: '/_teachers/shifts2',
        name: 'teachers|shifts2',
        component: () =>
            import('@/views/teachers/shifts2.vue')
    },
    {
        path: '/_teachers/settings/info',
        name: 'teachers|settings|info',
        component: () =>
            import('@/views/teachers/settings-info.vue')
    },
    {
        path: '/_teachers/freedays',
        name: 'teachers|freedays',
        component: () =>
            import('@/views/teachers/freedays.vue')
    },
    {
        path: '/_teachers/groups',
        name: 'teachers|settings|groups',
        component: () =>
            import('@/views/teachers/groups.vue')
    },
    {
        path: '/_teachers/groups/edit/:d',
        name: 'teachers|settings|groups-edit',
        component: () =>
            import('@/views/teachers/groups-edit.vue')
    },
    {
        path: '/_teachers/groups/add',
        name: 'teachers|settings|groups-add',
        component: () =>
            import('@/views/teachers/groups-add.vue')
    },
    {
        path: '/_teachers/settings/send',
        name: 'teachers|settings|send',
        component: () =>
            import('@/views/teachers/settings-send.vue')
    },
    {
        path: '/_teachers/reports/daily',
        name: 'teachers|reports|daily',
        component: () =>
            import('@/views/teachers/reports.vue')
    },
    {
        path: '/_teachers/reports/between',
        name: 'teachers|reports|between',
        component: () =>
            import('@/views/teachers/reports-between.vue')
    },
    {
        path: '/_teachers/reports/ask',
        name: 'teachers|reports|ask',
        component: () =>
            import('@/views/teachers/ask.vue')
    },
    {
        path: '/_teachers/overview/:id',
        name: 'teachers|reports|overview',
        component: () =>
            import('@/views/teachers/overview.vue')
    },
    {
        path: '/_teachers/mails/send',
        name: 'teachers|mails|send',
        component: () =>
            import('@/views/teachers/mails-send.vue')
    },
    {
        path: '/_teachers/mails/list',
        name: 'teachers|mails|list',
        component: () =>
            import('@/views/teachers/mails-list.vue')
    },
]