export default [
    {
        path: '/_results',
        name: 'results|home',
        component: () => import('@/views/results/index.vue')
    },
    {
        path: '/_results/link',
        name: 'results|link',
        component: () => import('@/views/results/link.vue')
    },
    {
        path: '/_results/history',
        name: 'results|history',
        component: () => import('@/views/results/history.vue')
    },
    {
        path: '/_results/stats',
        name: 'results|stats',
        component: () => import('@/views/results/stats.vue')
    },
    {
        path: '/archive?type=results',
        name: 'archive_results',
        component: () => import('@/views/students/archive.vue')
    },
    {
        path: '/results-primary-link/:s',
        name: 'results-primary-link',
        component: () => import('@/views/results/results-primary-link.vue'),
        meta: {
            layout: 'full',
        },
    },
]