export default [
    {
        path: '/_mentors',
        name: 'mentors|home',
        component: () => import('@/views/mentors/index.vue')
    },
    {
        path: '/_mentors/files',
        name: 'mentors|files',
        component: () => import('@/views/mentors/files.vue')
    },
    {
        path: '/_mentors/kushof',
        name: 'mentors|kushof',
        component: () => import('@/views/mentors/kushof.vue')
    },
    {
        path: '/archive?type=mentors',
        name: 'archive_mentors',
        component: () => import('@/views/students/archive.vue')
    },
    {
        path: '/_mentors/create',
        name: 'mentors|create',
        component: () => import('@/views/mentors/create.vue')
    },
    {
        path: '/_mentors/create2/1',
        name: 'mentors|create21',
        component: () => import('@/views/mentors/create2.vue')
    },
    {
        path: '/_mentors/create2/2',
        name: 'mentors|create22',
        component: () => import('@/views/mentors/create2.vue')
    },
    {
        path: '/que/:id',
        name: 'que',
        component: () => import('@/views/public/que.vue'),
        meta: {
            layout: 'full',
        },
    },
    {
        path: '/gque2/:id',
        name: 'gque',
        component: () => import('@/views/public/que2.vue'),
        meta: {
            layout: 'full',
        },
    },
    {
        path: '/que2/:id',
        name: 'gque',
        component: () => import('@/views/public/que2.vue'),
        meta: {
            layout: 'full',
        },
    },
]