export default [
    {
        path: '/_xteacher/login',
        name: 'xteacher|login',
        component: () => import('@/views/xteacher/login.vue'),
        meta: {
            layout: 'full',
        },
    },
    {
        path: '/_xteacher/j/:c',
        name: 'xteacher|login-direct',
        component: () => import('@/views/xteacher/login.vue'),
        meta: {
            layout: 'full',
        },
    },
    {
        path: '/_xteacher',
        name: 'xteacher|home',
        component: () => import('@/views/xteacher/home.vue')
    },
    {
        path: '/_xteacher/tasks',
        name: 'xteacher|tasks',
        component: () => import('@/views/xteacher/tasks.vue')
    },
    {
        path: '/_xteacher/weeklyplan',
        name: 'xteacher|weeklyplan',
        component: () => import('@/views/xteacher/weeklyplan.vue')
    },
    {
        path: '/_xteacher/asks',
        name: 'xteacher|asks',
        component: () => import('@/views/xteacher/asks.vue')
    },
    {
        path: '/_xteacher/mails',
        name: 'xteacher|mails',
        component: () => import('@/views/xteacher/mails.vue')
    },
    {
        path: '/_xteacher/rate',
        name: 'xteacher|rate',
        component: () => import('@/views/xteacher/rate.vue')
    },
    {
        path: '/_xteacher/myreport',
        name: 'xteacher|myreport',
        component: () => import('@/views/xteacher/myreport.vue')
    },
    {
        path: '/_xteacher/logout',
        name: 'xteacher|logout',
        component: () => import('@/views/xteacher/logout.vue')
    },
    {
        path: '/_xteacher/attend',
        name: 'xteacher|attend',
        component: () => import('@/views/xteacher/attend.vue')
    },
    {
        path: '/_xteacher/health',
        name: 'xteacher|health',
        component: () => import('@/views/xteacher/health.vue')
    },
    {
        path: '/_xteacher/report',
        name: 'xteacher|report',
        component: () => import('@/views/xteacher/report.vue')
    },
    {
        path: '/_xteacher/forward',
        name: 'xteacher|forward',
        component: () => import('@/views/xteacher/forward.vue')
    },
]