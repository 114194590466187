export default [
    {
        path: '/_committees',
        name: 'committees|home',
        component: () => import('@/views/committees/index.vue')
      },
      {
        path: '/_committees/create',
        name: 'committees|create',
        component: () => import('@/views/committees/create.vue')
      },
      {
        path: '/_committees/history',
        name: 'committees|history',
        component: () => import('@/views/committees/history.vue')
      },
      {
          path: '/archive?type=committees',
          name: 'archive_committees',
          component: () => import('@/views/students/archive.vue')
      },
      {
        path: '/_committees/kushof',
        name: 'committees|kushof',
        component: () => import('@/views/committees/kushof/index.vue')
      },
      {
        path: '/_committees/kushof/:id',
        name: 'committees|kushof-editor',
        component: () => import('@/views/committees/kushof/editor.vue'),
        meta: {
          layout: 'full',
        },
      },
      {
        path: '/_committees/settings',
        name: 'committees|settings',
        component: () => import('@/views/committees/settings.vue')
      },
]