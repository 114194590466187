import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import router from './router'
import store from './store'
import App from './App.vue'
import CKEditor from '@ckeditor/ckeditor5-vue2';

// Global Varaibles

//
global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;

//
window.api = "https://server.tahdir.net"
if(window.location.href.includes("localhost:80")){
  window.api = "http://127.0.0.1:3000";
}
if(window.location.href.includes('_teachers')){
  if(localStorage.getItem('user')){
    localStorage.setItem("jwt", JSON.parse(localStorage.getItem("user")).jwt)
  }
}

//
localStorage.setItem("api", window.api)

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use( CKEditor );

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

window.alert = function(text, status='bg-secondary'){
  $("#open-alert").click()
  setTimeout(function(){
    $("#alert-msg").html(`<div class='alert text-white g text-center ${status == 100 ? 'bg-success' : (status == 200 ? 'bg-danger' : 'bg-secondary')}'>${text}</div>`)
    if(text?.includes("تم الارسال بنجاح٫ يمكنك تصفح ارشيف الرسائل")){
      $("#alert-msg .alert").append(`<br><br>
      <a href='/archive' class='btn btn-primary'><i class='fa fa-archive'></i> عرض الارشيف</a>
      `)
    }
  }, 100)
}

if(!localStorage.getItem('first')){
  localStorage.setItem('first', 'true');
  localStorage.setItem("favs", `[{"title":"الحضور والغياب اليومي","url":"/_students/reports/daily"},{"title":"استعراض الطلاب","url":"/_students/list"},{"title":"شاشة الاستئذان","url":"/_students/scan/end"}]`)
}

window.checkPer = function(per){
  var s = false;
  if(localStorage.getItem('user')){
    var user = JSON.parse(localStorage.getItem('user'));
    if(user.subuser){
      if(user.pers.includes("*") || user.pers.includes(per)){
        s = true;
      }
    }else{
      s = true;
    }
  }
  return s;
};

Vue.prototype.checkPer = window.checkPer;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')