export default [
    {
        path: '/_calls',
        name: 'calls|home',
        component: () => import('@/views/calls/index.vue')
    },
    {
        path: '/_calls/requests',
        name: 'calls|requests',
        component: () => import('@/views/calls/requests.vue')
    },
    {
        path: '/_calls/manual',
        name: 'calls|manual',
        component: () => import('@/views/calls/manual.vue')
    },
    {
        path: '/_calls/settings',
        name: 'calls|settings',
        component: () => import('@/views/calls/settings.vue')
    },
]